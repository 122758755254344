<template>
  <div
    :key="getKey"
    class="p-1 pr-2"
  >
    <carousel
      :pagination-color="'#ff3732'"
      autoplay-hover-pause
      autoplay
      :per-page="getPerPage"
      navigation-enabled
      :pagination-enabled="false"
    >
      <slide
        v-for="recipe in data"
        :key="recipe.id"
        :class="{ 'mr-2': getPerPage > 1 }"
      >
        <b-card
          :img-src="recipe.PhotoUrl"
          img-top
          img-alt="Imagem da Receita"
          class="m-0 w-100 h-100"
          no-body
        >
          <b-card-title class="text-capitalize text-center p-1 mb-0">
            {{ recipe.Name.split(" | ")[0] }}
          </b-card-title>
          <hr class="mt-0 mb-0">
          <b-card-text class="p-1">
            <div class="recipe-description mt-1">
              <b-media no-body>
                <feather-icon
                  class="mr-1 text-band-primary font-weight-bolder"
                  icon="TrendingUpIcon"
                />
                <h6 class="text-band-primary">
                  Dificuldade
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <span class="font-weight-bold text-body-heading text-dark mr-1">{{
                  recipe.Difficulty
                }}</span>
              </div>
            </div>
            <div class="recipe-description mt-1">
              <b-media no-body>
                <feather-icon
                  class="mr-1 text-band-primary font-weight-bolder"
                  icon="ClockIcon"
                />
                <h6 class="text-band-primary">
                  Tempo de Preparo
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <span
                  class="font-weight-bold text-body-heading text-dark mr-1"
                >{{ resolvePrepTime(recipe.PrepTime) }}</span>
              </div>
            </div>
          </b-card-text>
          <b-row>
            <b-col cols="12">
              <b-button
                class="w-100 rounded-0 bg-band-primary b-band-primary"
                @click="openNewTab(recipe.RecipeUrl)"
              >
                Acesse a Receita
              </b-button>
            </b-col>
          </b-row>
          <b-card-text class="p-1 text-center">
            <p class="text-dark">
              Compartilhe essa receita
            </p>
            <div class="d-inline">
              <b-button
                variant="flat-success"
                @click="resolveWaMe(recipe)"
              >
                <font-awesome-icon
                  style="font-size: 25px !important"
                  :icon="['fab', 'whatsapp']"
                />
              </b-button>
              <b-button
                variant="flat-primary"
                :href="resolveMailTo(recipe)"
              >
                <font-awesome-icon
                  style="font-size: 25px !important"
                  :icon="['fas', 'envelope']"
                />
              </b-button>
            </div>
          </b-card-text>
        </b-card>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import {
  BCard,
  BCardText,
  BCardTitle,
  BMedia,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import { v4 as uuid } from 'uuid'

import { mapGetters } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    Carousel,
    Slide,
    BCard,
    BCardText,
    BCardTitle,
    BMedia,
    BRow,
    BCol,
    BButton,
    FeatherIcon,
  },
  data() {
    return {
      baseShareText: 'Olha essa receita que eu vi no The Chef da Band 👉',
    }
  },
  computed: {
    ...mapGetters('app', {
      breakpoint: 'currentBreakPoint',
    }),
    getKey() {
      return uuid()
    },
    getPerPage() {
      if (this.breakpoint === 'xs') return 1
      if (this.breakpoint === 'sm') return 1
      if (this.breakpoint === 'md') return 3
      if (this.breakpoint === 'lg') return 4
      if (this.breakpoint === 'xl') return 5

      return 1
    },
  },
  methods: {
    timeConvert(n) {
      const num = n
      const hours = num / 60
      const rhours = Math.floor(hours)
      const minutes = (hours - rhours) * 60
      const rminutes = Math.round(minutes)

      return { minutes: rminutes, hours: rhours }
    },
    resolvePrepTime(prepTime) {
      const time = this.timeConvert(prepTime)

      if (time.hours === 0) return `${time.minutes} minutos`

      const hoursText = `${time.hours} ${time.hours > 1 ? 'horas' : 'hora'}`
      const minutesText = `${time.minutes} ${time.minutes > 1 ? 'minutos' : 'minuto'}`

      if (time.minutes > 0) {
        return `${hoursText} e ${minutesText}`
      }

      return hoursText
    },
    openNewTab(url) {
      window.open(url, '_blank').focus()
    },
    resolveWaMe(recipe) {
      this.openNewTab(
        `https://wa.me/?text=${encodeURI(
          `${this.baseShareText} ${recipe.RecipeUrl}`,
        )}`,
      )
    },
    resolveMailTo(recipe) {
      return `mailto:?body=${this.baseShareText} ${recipe.RecipeUrl}`
    },
  },
  props: ['data'],
}
</script>
