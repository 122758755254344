import axios from 'axios'

export default () => axios.create({
  withCredentials: false,
  validateStatus(status) {
    return status >= 200 && status < 300
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
