import { pick, has } from 'lodash'
import routesStaging from './apis/staging'
import routesMaster from './apis/prod'
import base from './apis/clients/band'
import useAxios from './useAxios'

const development = process.env.NODE_ENV === 'development'

export default {
  axios: useAxios(),

  getRoute(route) {
    const routeTokens = route.split('.')

    if (development) {
      return `${
        routeTokens.length === 2
          ? base[`${routeTokens[0]}StagingUrl`]
          : base.baseUrlStaging
      }/${
        routeTokens.length === 2
          ? routesStaging[routeTokens[0]][routeTokens[1]]
          : routesStaging[routeTokens[0]]
      }`
    }

    return `${
      routeTokens.length === 2 ? base[`${routeTokens[0]}Url`] : base.baseUrl
    }/${
      routeTokens.length === 2
        ? routesMaster[routeTokens[0]][routeTokens[1]]
        : routesMaster[routeTokens[0]]
    }`
  },
  getAxiosOptions(options, route) {
    const url = this.getRoute(route)
    const match = url.match(/{(\w+)}/g) || []
    const { params = {} } = options
    const keys = []

    const parsedUrl = match.reduce((currentUrl, param) => {
      const key = param.replace(/{|}/g, '')

      keys.push(key)

      let result = ''

      if (!has(params, key)) {
        console.info(
          `[service]: missing parameter ${key} in params for route ${currentUrl} (recieved ${route})`,
        )
        result = currentUrl.replace(param, 0)
      } else {
        result = currentUrl.replace(param, params[key])

        params[key] = null
      }

      return result
    }, url)

    return {
      ...options,
      url: parsedUrl,
      baseURL: base.baseURL,
      params,
      urlParams: pick(options, keys),
    }
  },
  dispatch(target, options) {
    // eslint-disable-next-line no-param-reassign
    options = this.getAxiosOptions(options, target)

    return this.axios(options)
  },
}
