<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="`${msgGrp.senderId}-${index}`"
      class="chat"
      :class="{ 'chat-left': msgGrp.senderId === formattedChatData.contact.id }"
    >
      <div
        v-show="msgGrp.senderId === client.id || props.hideAvatar === false"
        class="chat-avatar"
      >
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="
            msgGrp.senderId === formattedChatData.contact.id
              ? formattedChatData.contact.avatar
              : profileUserAvatar
          "
        />
      </div>
      <div
        class="chat-body"
        :class="{ 'mr-0': props.hideAvatar }"
      >
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.id"
          class="pr-1"
          :class="{ 'chat-content': true, 'no-bg': !isText(msgData.msg), ...client.messageBodyClasses, }"
          :style="{ 'max-width': !isText(msgData.msg) ? '100% !important' : 'auto', 'min-width': !isText(msgData.msg) ? '100% !important' : 'auto', 'max-width': isText(msgData.msg) ? '75% !important' : 'auto' }"
        >
          <div
            v-if="messageIsCustom(msgData)"
          >
            <component
              :is="resolveCustomComponent(msgData.type)"
              :data="tryParseData(msgData.msg)"
            />
          </div>
          <p
            v-else
            :class="{'text-dark': msgGrp.senderId === client.id}"
            v-html="msgData.msg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import BandRecipeComponent from './renderers/BandRecipeComponent.vue'
import BandSurveyComponent from './renderers/BandSurveyComponent.vue'

export default {
  components: {
    BAvatar,
    BandRecipeComponent,
    BandSurveyComponent,
  },
  props: {
    client: {
      type: Object,
    },
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      props: 'getProps',
    }),
  },
  methods: {
    resolveCustomComponent(type) {
      return this.client.customComponentRenderers[type] ?? null
    },
    messageIsCustom(msgData) {
      return msgData.type.toLowerCase() !== 'text'
    },
    messageIsFromClient(id) {
      return id === this.client.id
    },
    tryParseData(data) {
      try {
        return JSON.parse(data)
      } catch {
        return data
      }
    },
    isText(value) {
      if (value[0] === '{' || value[0] === '[') return false

      return this.tryParseData(value).constructor.name === 'String'
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            type: msg.type,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [
              {
                msg: msg.message,
                time: msg.time,
                type: msg.type,
              },
            ],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>
