/* eslint-disable global-require */
import { v4 as uuidv4 } from 'uuid'

export default [
  {
    /* Definição do contato do chat */
    id: uuidv4(),
    client: 'band-receitas',
    fullName: 'Carol',
    avatar: require('@/assets/images/avatars/1.png'),
    logo: require('@/assets/images/logo/band.png'),
    onlyPicture: true,
    hideName: true,
    status: 'online',

    messageBodyClasses: {
      'pr-0': true,
    },
    customComponentRenderers: {
      Receita: 'band-recipe-component',
      Survey: 'band-survey-component',
    },

    /* Configuração do smartChat. É utilizado para fazer a autenticação e enviar os requests autenticados seguintes. */
    configuration: {
      from: '',
      fromName: '',
      clientId: 'web-client',
      clientToken: '60F05BBF-FD5E-4913-97E4-361DD33A38C0',
      clientNumber: 'web_bandreceitas2',
      priorityGroupId: 0,
    },
  },
]
