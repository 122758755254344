<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <!-- Main Area -->
  <section
    class="chat-app-window shadow"
    :class="{
      'vh-100': props.fullscreen,
      'h-100': !props.fullscreen,
    }"
  >
    <!-- Start Chat StartPage -->
    <div
      v-if="!activeChat.contact"
      class="start-chat-area"
    >
      <div class="mb-1 start-chat-icon">
        <feather-icon
          icon="MessageSquareIcon"
          size="56"
        />
      </div>
      <h3 class="sidebar-toggle start-chat-text">
        {{
          customMessage
            ? customMessage
            : "Nenhum cliente foi informado na URL 😕."
        }}
      </h3>
    </div>

    <!-- Chat Content -->
    <div
      v-else
      class="active-chat"
    >
      <!-- Chat Navbar -->
      <div class="chat-navbar">
        <header class="chat-header">
          <!-- Avatar & Name -->
          <div class="d-flex align-items-center">
            <!-- Toggle Icon -->
            <div
              v-if="false"
              class="sidebar-toggle d-block d-lg-none mr-1"
            >
              <feather-icon
                icon="MenuIcon"
                class="cursor-pointer"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>

            <b-avatar
              v-if="client.onlyPicture === false"
              size="36"
              :src="activeChat.contact.logo"
              class="mr-1 cursor-pointer badge-minimal"
              badge
              :badge-variant="
                resolveAvatarBadgeVariant(activeChat.contact.status)
              "
              @click.native="shallShowActiveChatContactSidebar = true"
            />
            <b-img
              v-else
              :src="activeChat.contact.logo"
              class="mr-1 cursor-pointer badge-minimal"
            />
            <h6
              v-if="!client.hideName"
              class="mb-0"
            >
              {{ activeChat.contact.fullName }}
            </h6>
          </div>

          <!-- Contact Actions -->
          <div
            v-if="false"
            class="d-flex align-items-center"
          >
            <feather-icon
              icon="PhoneCallIcon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-1"
            />
            <feather-icon
              icon="VideoIcon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-1"
            />
            <feather-icon
              icon="SearchIcon"
              size="17"
              class="cursor-pointer d-sm-block d-none mr-50"
            />
            <div class="dropdown">
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="17"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item> View Contact </b-dropdown-item>
                <b-dropdown-item> Mute Notifications </b-dropdown-item>
                <b-dropdown-item> Block Contact </b-dropdown-item>
                <b-dropdown-item> Clear Chat </b-dropdown-item>
                <b-dropdown-item> Report </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </header>
      </div>

      <!-- User Chat Area -->
      <vue-perfect-scrollbar
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area"
      >
        <chat-log
          ref="currentChatLog"
          :chat-data="activeChat"
          :client="client"
          :profile-user-avatar="'undefined'"
        />
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form
        class="chat-app-form"
        @submit.prevent="sendMessage"
      >
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-input
            v-model="chatInputMessage"
            placeholder="Digite sua mensagem..."
            autocomplete="off"
            :disabled="chatClosed || !hasChat"
          />
        </b-input-group>
        <b-button
          class="pr-0 pl-0"
          variant="flat"
          type="submit"
        >
          <feather-icon
            size="2x"
            icon="SendIcon"
          />
        </b-button>
      </b-form>
    </div>
  </section>

  <!-- Active Chat Contact Details Sidebar -->
  <!-- <chat-active-chat-content-details-sidedbar
      v-if="false"
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    /> -->

  <!-- Sidebar -->
  <!-- <portal
      v-if="false"
      to="content-renderer-sidebar-left"
    >
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal> -->
</template>

<script>
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'

import { ref, onUnmounted, nextTick } from '@vue/composition-api'
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BImg,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints, $chatStatus } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
// import ChatLeftSidebar from './ChatLeftSidebar.vue'
import { v4 as uuid } from 'uuid'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import chatStoreModule from './chatStoreModule'
// import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BImg,

    VuePerfectScrollbar,

    ChatLog,
    FeatherIcon,
  },
  props: {
    // Objeto preenchido do client localizado no arquivo de configuração pelo wrapper.
    client: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('chatEngine', ['getChat', 'getChatFromContact']),
    ...mapGetters({
      props: 'getProps',
    }),
    hasChat() {
      return this.getChat !== null
    },
  },
  created() {
    // Encerrar o chat antes da aba fechar
    window.addEventListener('beforeunload', this.beforeClose, false)

    // Evento global para encerrar o chat
    this.$root.$on('close-chat', this.closeChat)

    this.activeChat = this.getChatFromContact(this.client.id)
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Registra o banco de mockup que hospeda o modelo inicial da conversa
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // Unregister ao sair
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // Carrega o modelo inicial do chat no banco de mockup
    const chatsContacts = ref([])
    const contacts = ref([])

    // ------------------------------------------------
    // Abre um chat específico
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = userId => {
      // Reset send message input value
      chatInputMessage.value = ''

      store.dispatch('app-chat/getChat', { userId }).then(response => {
        activeChat.value = response.data

        // Set unseenMsgs to 0
        const contact = chatsContacts.value.find(c => c.id === userId)
        if (contact) contact.chat.unseenMsgs = 0

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser').then(response => {
        profileUserData.value = response.data
        shallShowUserProfileSidebar.value = true
      })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      // Chat criado no SmartChat
      chat: null,
      customMessage: null,
      chatClosed: false,

      requestingStatus: false,
      requestingMessages: false,

      scrollToBottomInChatLog,
    }
  },
  timers: {
    getChatStatus: {
      autostart: false,
      repeat: true,
      time: 3 * 1000,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('chatEngine', ['getStatus', 'setChatStatus']),
    ...mapActions('chatEngine', {
      sendMessageToApi: 'sendMessage',
    }),
    scrollToBottom() {
      this.$nextTick(() => {
        const scrollEl = this.$refs.refChatLogPS.$el
        scrollEl.scrollTop = scrollEl.scrollHeight
      })
    },
    invalidate(message) {
      this.customMessage = message

      // Remover o contato do objeto do chat impede que a navbar seja mostrada, então o overlay
      // entra no lugar.
      this.activeChat.contact = null
    },
    sendMessage(toApi = true, toInterface = true, message = null) {
      if (!this.chatInputMessage && !message) return

      const payload = {
        contactId: this.activeChat.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: this.profileUserDataMinimal.id,
        message: message || this.chatInputMessage,
        type: 'Text',
        time: new Date(),
        client: true,
        id: uuid(),
      }

      this.chatInputMessage = ''

      if (toApi) {
        this.sendMessageToApi(payload.message).then(() => {
          this.scrollToBottom()
        })
      }

      if (toInterface) {
        this.activeChat.chat.chat.push(payload)
        this.scrollToBottom()
      }

      // if (this.getChat.closed) {
      //   this.$root.$emit('rejoin')

      //   this.$nextTick(() => {
      //     setTimeout(() => {
      //       doSendMessage(false)
      //     }, 100)
      //   })
      // } else {
      //   doSendMessage()
      // }
    },
    beforeClose() {
      return this.closeChat()
    },
    closeChat(event) {
      // Envia o beacon
      this.setChatStatus($chatStatus.closed).then(() => true)

      this.$timer.stop('getChatStatus')

      if (event.reason === 'end-of-flow') {
        this.activeChat.chat.chat.push({
          senderId: this.client.id,
          type: 'Text',
          message: 'Ah! Muito obrigada pelo envio de sua pesquisa. Nos vemos outra hora! Até lá!',
          time: new Date(),
          id: uuid(),
        })
      }

      this.chatClosed = true
    },
    getChatStatus() {
      if (!this.hasChat || this.requestingStatus) return

      this.requestingStatus = true

      this.getStatus(this.client.id)
        .then(response => {
          if (response.includeMessages.length > 0) {
            const current = this.activeChat.chat.chat.map(c => c.id)

            response.includeMessages.forEach(message => {
              if (!current.includes(message.id)) { this.activeChat.chat.chat.push(message) } else {
                console.error('duped message')
              }
            })

            this.scrollToBottom()
          }
        })
        .finally(() => {
          this.requestingStatus = false
        })
    },
    loadCurrentChat() {
      this.chat = this.getChat

      this.$timer.start('getChatStatus')

      this.sendMessage(true, false, '{{welcome}}')
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
