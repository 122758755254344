import api from '@/service'

const actions = {
  saveSurvey({ commit, rootGetters }, payload) {
    const auth = rootGetters['auth/getAuth']
    const { chatId } = rootGetters['chatEngine/getChat']

    return api.dispatch('SaveSurvey', {
      method: 'post',
      data: {
        ...auth,
        chatId,
        surveyId: 1,
        questionId: 1,
        ...payload,
      },
    }).then(response => {
      commit('SET_SURVEY', response.data)

      return response.data
    })
  },
}

const mutations = {
  SET_SURVEY(state, survey) {
    state.survey = survey
  },
}

const getters = {
  getSurvey: state => state.survey,
}

const state = {
  survey: {
    message: {},
  },
}

export default {
  actions,
  mutations,
  getters,
  state,

  namespaced: true,
}
