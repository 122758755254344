import Vue from 'vue'
import Vuex from 'vuex'

import globalModule from './global'

// Theme Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// SmartChat
import auth from './auth'
import chatEngine from './chatEngine'
import survey from './survey'

Vue.use(Vuex)

export default new Vuex.Store({
  ...globalModule,

  modules: {
    // Theme
    app,
    appConfig,
    verticalMenu,

    // Integration
    auth,
    chatEngine,
    survey,
  },
  strict: process.env.DEV,
})
