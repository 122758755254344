<template>
  <div
    class="d-flex"
    :style="{'max-width': selectedRating === null ? '75% !important' : '100% !important'}"
    no-body
  >
    <div
      class="d-flex w-100 ratings"
      :class="{'justify-content-around': selectedRating === null, 'justify-content-end': selectedRating !== null}"
    >
      <div
        v-for="(rating, index) in ratings"
        :key="index"
        @click="select(rating)"
      >
        <span v-if="selectedRating === null || selectedRating === rating">
          {{ rating.emoji }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  data() {
    return {
      selectedRating: null,
      selectedIndex: -1,

      ratings: [
        {
          emoji: '😡',
          value: 1,
        },
        {
          emoji: '😞',
          value: 2,
        },
        {
          emoji: '😐',
          value: 3,
        },
        {
          emoji: '🙂',
          value: 4,
        },
        {
          emoji: '😄',
          value: 5,
        },
      ],
    }
  },
  methods: {
    ...mapActions('survey', ['saveSurvey']),
    select(rating) {
      this.saveSurvey({
        answerId: rating.value,
        answerObservation: null,
        customerMessage: null,
      }).then(() => {
        // Seleciona a avaliação
        this.selectedRating = rating
        this.selectedIndex = this.ratings.indexOf(rating)

        // Finaliza o chat
        this.$root.$emit('close-chat', {
          reason: 'end-of-flow',
        })
      }).catch(() => {
        alert('Não foi possível enviar sua pesquisa nesse momento.')
      })
    },
  },
}
</script>
