<template>
  <div
    class="chat--wrapper max-h-100"
    :class="{ 'wrapper--bottom-right': !isFullscreen }"
    :style="{
      height: props.height,
      width: props.width,
      'max-height': props.height,
      'max-width': props.width,
    }"
  >
    <chat
      ref="chatWindow"
      :client="currentClient"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import clients from '@/config'

import Chat from './Chat.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    Chat,
  },
  props: {
    // Cliente recebido na rota em :client. Esse valor é utilizado para fazer o find no arquivo de configuração.
    client: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentClient: null,
    }
  },
  created() {
    this.currentClient = clients.find(
      client => client.client === this.client,
    )

    if (!this.currentClient) {
      console.error(
        '[c1]: client must be defined on uri like domain.net/chat/{client}. Overlay will be shown.',
      )
    }

    this.setEvents()

    this.setProps({
      ...this.$route.query,
      client: this.currentClient.client,
    }).then(() => {
      if (this.isFullscreen) {
        this.doClientAuth()
      }
    })
  },
  computed: {
    ...mapGetters('auth', {
      requestAuthentication: 'getAuth',
    }),
    ...mapGetters({
      props: 'getProps',
    }),
    isFullscreen() {
      return this.props.fullscreen === true
    },
    chatElement() {
      return this.$refs.chatWindow
    },
  },
  methods: {
    ...mapActions(['setProps']),
    ...mapActions('auth', ['clientAuth']),
    ...mapActions('chatEngine', ['requestChat']),
    // Invalida a tela caso a autenticação ou a abertura do chat não aconteça
    invalidateChild() {
      console.error('[c1]: endpoint fetch failure')

      // Remove o chat preenchido pelo loadCurrentChat e exibe o overlay
      this.chatElement.invalidate(
        'Não é possível iniciar o seu atendimento agora.',
      )
    },
    setEvents() {
      this.$root.$on('rejoin', this.doClientAuth)
    },
    doClientAuth() {
      this.clientAuth(this.currentClient.configuration)
        .then(() => {
          this.requestChat()
            .then(() => {
              // Starts the request loop
              this.chatElement.loadCurrentChat()
            })
            .catch(this.invalidateChild)
        })
        .catch(this.invalidateChild)
    },
  },
}
</script>
