const actions = {
  setProps({ commit }, props) {
    if (props) { return commit('SET_PROPS', props) }

    return 0
  },
}

const mutations = {
  SET_PROPS(state, props) {
    const { hideAvatar } = props

    state.props = {
      fullscreen: true,
      hideAvatar: hideAvatar === 'true',
      client: props.client,
    }
  },
}

const getters = {
  getProps: state => state.props,
}

const state = {
  props: {
    fullscreen: true,
    hideAvatar: false,
    height: 'auto',
    width: 'auth',
  },
}

export default {
  actions,
  mutations,
  getters,
  state,
}
