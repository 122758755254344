import api from '@/service'

const actions = {
  clientAuth({ commit }, payload) {
    return api.dispatch('ClientAuth', {
      method: 'post',
      data: payload,
    }).then(response => {
      commit('SET_AUTH', {
        ...response.data.data,
        clientNumber: payload.clientNumber,
      })

      return response.data
    })
  },
}

const mutations = {
  SET_AUTH(state, auth) {
    state.auth = {
      from: auth.from,
      sessionToken: auth.sessionToken,
      clientNumber: auth.clientNumber,
      chatCreatedFrom: '',
    }
  },
}

const getters = {
  getAuth: state => state.auth,
}

const state = {
  auth: {},
}

export default {
  actions,
  mutations,
  getters,
  state,

  namespaced: true,
}
