import { merge } from 'lodash'
import prodUrls from '@/service/apis/prod'
import stagingUrls from '@/service/apis/staging'

const development = process.env.NODE_ENV === 'development'

export default merge(development ? stagingUrls : prodUrls, {
  baseUrl: 'https://c1chatprodapi.contactone.com.br/api',
  baseUrlStaging: 'https://c1chatapistaging.azurewebsites.net/api',
  dialogFlowUrl: 'https://c1chaturaprodapi.azurewebsites.net/api',
  dialogFlowStagingUrl: 'https://c1chaturaapistaging.azurewebsites.net/api',
  code: 'not-fixed',
})
