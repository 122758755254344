import api from '@/service'

import clients from '@/config'
import { v4 as uuidv4 } from 'uuid'

import { $chatStatus } from '@themeConfig'

const actions = {
  requestChat({ commit, rootGetters }) {
    const auth = rootGetters['auth/getAuth']

    return api
      .dispatch('RequestChat', {
        method: 'post',
        data: {
          ...auth,
          vcn: 1,
        },
      })
      .then(response => {
        commit('SET_CHAT', response.data.data)

        return response.data
      })
  },
  getStatus({
    commit, getters, rootGetters, dispatch,
  }, clientId) {
    const auth = rootGetters['auth/getAuth']
    const { chatId } = getters.getChat

    return api
      .dispatch('GetChatStatus', {
        method: 'post',
        data: {
          ...auth,
          chatId,
        },
      })
      .then(response => {
        const { data } = response.data

        commit('SET_STATUS', data)

        if (parseInt(data.chat.unreadMessagesCount, 10) > 0) {
          return dispatch('getMessages', { unreadOnly: true, clientId }).then(result => ({
            includeMessages: result,
          }))
        }

        return {
          includeMessages: [],
        }
      })
  },
  getMessages({ rootGetters, getters, dispatch }, payload = { unreadOnly: true }) {
    const auth = rootGetters['auth/getAuth']
    const { chatId } = getters.getChat

    return api
      .dispatch('GetChatMessages', {
        method: 'post',
        data: {
          ...auth,
          chatId,
          unreadOnly: payload.unreadOnly ? '1' : '0',
        },
      })
      .then(response => {
        const { data } = response.data

        dispatch(
          'setMessagesAsRead',
          data.messages.map(m => m.messageId),
        )

        return data.messages.filter(m => m.fromType === 'Agent').map(message => {
          let formattedMessage = message.text

          formattedMessage = formattedMessage.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

          return {
            senderId: payload.clientId,
            type: message.messageType,
            message: formattedMessage,
            time: message.sentAt,
            id: message.messageId,
          }
        })
      })
  },
  sendMessage({ rootGetters, getters }, text) {
    const auth = rootGetters['auth/getAuth']
    const { chatId } = getters.getChat

    return api
      .dispatch('dialogFlow.SendMessage', {
        method: 'post',
        data: {
          ...auth,
          chatId,
          text,
          // Valores fixos do backend, não variam de client para client
          sessionToken: 'CDCC21DC-2089-4550-97CD-70B5763DF55B',
          messageType: 'Text',
          attachmentMimeType: '',
          attachmentUrl: '',
          attachmentFileName: '',
          attachmentFileSize: 0,
        },
      })
      .then(response => ({
        appendMessage: {
          senderId: 11,
          message: text,
          time: new Date(),
          id: '',
        },
        ...response.data,
      }))
  },
  setMessagesAsRead({ getters, rootGetters }, messageIds) {
    const auth = rootGetters['auth/getAuth']
    const { chatId } = getters.getChat

    return api.dispatch('SetChatMessageAsRead', {
      method: 'post',
      data: {
        ...auth,
        chatId,
        chatMessages: messageIds,
      },
    })
  },
  setChatStatus({ rootGetters, getters, commit }, status) {
    const auth = rootGetters['auth/getAuth']
    const { chatId } = getters.getChat

    const payload = {
      ...auth,
      chatId,
      chatStatusId: status,
    }

    const jsonPayload = JSON.stringify(payload)

    navigator.sendBeacon(
      api.getRoute('SetChatStatus'),
      jsonPayload,
    )

    if (status === $chatStatus.closed) commit('SET_CHAT_CLOSED')

    // commit('SET_STATUS', null)
    // commit('SET_CHAT', null)
  },
}

const mutations = {
  SET_CHAT(state, chat) {
    state.chat = chat
  },
  SET_CHAT_CLOSED(state) {
    state.chat.closed = true
  },
  SET_STATUS(state, status) {
    state.status = status.chat
  },
}

const getters = {
  getChat: state => state.chat,
  getChatFromContact: state => contactId => ({
    chat: state.chats.find(chat => chat.userId === contactId),
    contact: state.contacts.find(contact => contact.id === contactId),
  }),
}

const state = {
  chat: null,

  contacts: clients,
  chats: clients.map(client => ({
    id: uuidv4(),
    userId: client.id,
    unseenMsgs: 0,
    chat: [],
  })),
}

export default {
  actions,
  mutations,
  getters,
  state,

  namespaced: true,
}
