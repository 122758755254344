import chat from './chat'
import auth from './auth'
import survey from './survey'
import dialogFlowChat from './dialogFlowChat'

export default {
  ...chat,
  ...auth,
  ...survey,

  dialogFlow: {
    ...dialogFlowChat,
  },
}
