import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/chat/:client?',
      name: 'chat-view',
      component: () => import('@/views/chat/ChatWrapper.vue'),
      meta: {
        layout: 'vertical',
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
      props: true,
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   if (to.name === 'auth-login') return next()

//   // Redirect if logged in
//   // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   //   const userData = getUserData()
//   //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   // }

//   return next()
// })

export default router
